import { GtmTracker } from '@/modules/common/services/analytics';

const trackElliotBannerAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'trade_home',
  digital_experience: 'investor',
  experience_section: 'trade'
});

export function trackBannerClick() {
  trackElliotBannerAmplitudeEvent({
    event_type: 'click',
    event_name: 'click_banner',
    page_screen_section: 'hero'
  });
}
