import { NavigationGuard } from 'vue-router';

import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

export const beforeEnterElliotUniverse: NavigationGuard = (
  _to,
  _from,
  next
) => {
  const hasFFEnabled = FeatureFlagsModule.isEnabled(
    FeatureFlags.ENABLE_COPA_ELLIOT
  );

  if (!hasFFEnabled) {
    return next({ name: 'home' });
  }

  next();
};
