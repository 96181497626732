
import { Component, Prop } from 'vue-property-decorator';

import { ServicesSection } from '../ServicesSection';
import { WatchList } from '../WatchList';
import { TradeStatus } from './components/TradeStatus';
import { SidebarTypes } from '@/modules/trade/views/TradeView/components/TradeSidebar';
import { GeneralFooter } from './components/GeneralFooter';
import { PositionsSummarySection } from './components/PositionsSummarySection';
import FeeTransparencyDisclaimer from '@/modules/trade/views/TradeView/components/FeeTransparencyDisclaimer/FeeTransparencyDisclaimer.vue';
import { isImpersonate } from '@/modules/common/services/partner';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { AccountStatus, IHeroBalances } from '@/modules/trade/services/account';
import {
  trackHomeBrokerClick,
  trackHelpClick,
  trackStatementAndNotesClick
} from '@/modules/trade/views/TradeView/trackers';
import { MFAModules } from '@/modules/authentication/views/SecurityView/components/MultiFactorAuthentication';
import { RequestStatus } from '@/modules/trade/store/trade';
import { AvailableBalance } from '@/modules/trade/components/AvailableBalance';

import { useDashStore } from '@/modules/common/store/DashModule';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

import BannerDark from '@/assets/imgs/elliot/banner_dark.png';
import BannerLight from '@/assets/imgs/elliot/banner_light.png';

import * as TrackEvents from './track-events';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    ServicesSection,
    WatchList,
    TradeStatus,
    GeneralFooter,
    PositionsSummarySection,
    AvailableBalance,
    FeeTransparencyDisclaimer
  }
})
export default class GeneralSidebarList extends mixins(RouteMixin) {
  @tradePositionsModule.State('userHasBrokerAccount')
  readonly userHasBrokerAccount!: boolean;

  @tradePositionsModule.State('balancesBrokerRequestStatus')
  readonly balancesBrokerRequestStatus!: RequestStatus;

  @tradePositionsModule.Action('fetchBalances')
  readonly fetchBalances!: Function;

  @Prop({ type: Object, required: false })
  readonly balances?: IHeroBalances;

  @Prop({ type: Object, required: false })
  readonly tradeStatus?: AccountStatus;

  @Prop({ type: Function, default: () => {} })
  readonly onAccessHomeBrokerClick!: Function;

  @Prop({ type: Function, required: true })
  readonly setPanel!: Function;

  @Prop({ type: Function, required: true })
  readonly getWatchlistSubscription!: () => {};

  @Prop({ type: Function, default: () => {} })
  readonly openBrokerModal!: Function;

  private isImpersonate = isImpersonate();

  readonly dashStore = useDashStore();

  private get homeBrokerButtonEnable() {
    if (this.isImpersonate || this.updatedAccountRegulation) {
      return false;
    }

    return true;
  }

  get elliotBanner() {
    return this.isDarkMode ? BannerDark : BannerLight;
  }

  get unifiedAccountAvailableBalance() {
    return {
      availableOnAccountValue: this.balances?.account?.availableBalance,
      availableToOperateValue: this.balances?.account?.detail?.availableBalance,
      toSettleValue: this.balances?.account?.detail?.liquidatedBalance
    };
  }

  get hasBalancesBrokerRequestFailed() {
    return this.balancesBrokerRequestStatus === RequestStatus.Error;
  }

  get isBalancesBrokerRequestLoading() {
    return this.balancesBrokerRequestStatus === RequestStatus.Loading;
  }

  get isMarketNotAvailable() {
    return !this.tradeStatus?.marketAvailable?.isMarketAvailable;
  }

  get statusMarketAvailable() {
    return this.tradeStatus?.marketAvailable;
  }

  get isDarkMode() {
    return this.dashStore.isDarkMode;
  }

  get hasCopaElliotEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.ENABLE_COPA_ELLIOT);
  }

  /**
   * This is temporally, this panel transition will be change for a new all positions page
   */
  public setSubscriptionsPanel(setPanel: (param: SidebarTypes) => void) {
    setPanel(SidebarTypes.SubscriptionsList);
    window.scrollTo(0, 0);
  }

  /**
   * This is temporally, this panel transition will be change for a new all watchlist page
   */
  public setWatchlistPanel(setPanel: (param: SidebarTypes) => void) {
    setPanel(SidebarTypes.Watchlist);
    window.scrollTo(0, 0);
  }

  public goToSecurity() {
    trackHomeBrokerClick(
      'Ativar Home Broker - Cadastre a verificação em duas etapas'
    );
    this.$router.push({
      name: 'security',
      query: { module: MFAModules.TRADE }
    });
  }

  handleHelpClick() {
    trackHelpClick();
  }

  public trackAccountStatements() {
    trackStatementAndNotesClick();
  }

  goToElliotCupPage() {
    TrackEvents.trackBannerClick();
    this.$router.push({
      name: 'elliot-universe'
    });
  }

  private get updatedAccountRegulation() {
    return this.tradeStatus && !this.tradeStatus.enabledBuy;
  }

  private setSummaryPanels(panel: SidebarTypes) {
    this.setPanel(panel);
    window.scrollTo(0, 0);
  }
}
