import { GtmTracker } from '@/modules/common/services/analytics';

const trackElliotUniverseAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'elliot_redirect',
  digital_experience: 'investor',
  experience_section: 'trade'
});

export function trackLoad() {
  trackElliotUniverseAmplitudeEvent({
    event_type: 'load',
    event_name: 'load-redirect'
  });
}

export function trackClose() {
  trackElliotUniverseAmplitudeEvent({
    event_type: 'click',
    event_name: 'close'
  });
}

export function trackOpenLink() {
  trackElliotUniverseAmplitudeEvent({
    event_type: 'click',
    event_name: 'open_elliot'
  });
}
