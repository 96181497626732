import { GtmTracker } from '@/modules/common/services/analytics';

const trackElliotModalAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'home',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackModalLoad() {
  trackElliotModalAmplitudeEvent({
    event_type: 'load',
    event_name: 'load',
    page_screen_section: 'popup'
  });
}

export function trackCloseModal() {
  trackElliotModalAmplitudeEvent({
    event_type: 'click',
    event_name: 'close',
    page_screen_section: 'popup'
  });
}

export function trackOpenLink() {
  trackElliotModalAmplitudeEvent({
    event_type: 'click',
    event_name: 'open_elliot',
    page_screen_section: 'popup'
  });
}
