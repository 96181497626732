
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ContextColorProvider } from '@warrenbrasil/nebraska-web';
import { HomeTemplate } from '@/modules/home/components/HomeTemplate';
import { HomeIllustration } from '@/modules/home/components/HomeIllustration';
import { HomeNews } from '@/modules/home/components/HomeNews';
import { HomeWidgets } from '@/modules/home/components/HomeWidgets';
import { HomeCards } from '@/modules/home/components/HomeCards';
import { HomeRegister } from '@/modules/home/components/HomeRegister';
import { HomeCash } from '@/modules/home/components/HomeCash';
import { ModalSuggestions as PensionModalSuggestions } from '@/modules/pension/components/ModalSuggestions';
import { ElliotCupModal } from '@/modules/home/components/ElliotCupModal';
import { clearNextRoutes } from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { UIModule } from '@/modules/common/store/UIModule';
import * as trackEvents from './track-events';

import {
  getBalances,
  BalancesResponse,
  IBalancesImages
} from '@/modules/home/services/bff';
import HomeBalanceSummary from '@/modules/home/components/HomeBalanceSummary/HomeBalanceSummary.vue';
import HomeAccountStatus from '@/modules/home/components/HomeAccountStatus/HomeAccountStatus.vue';
import HomeSuitability from '@/modules/home/components/HomeSuitability/HomeSuitability.vue';
import { AccountStates } from '@/modules/common/services/http/account';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    ContextColorProvider,
    HomeTemplate,
    HomeIllustration,
    HomeNews,
    HomeWidgets,
    HomeCards,
    HomeCash,
    PensionModalSuggestions,
    HomeBalanceSummary,
    HomeRegister,
    HomeAccountStatus,
    HomeSuitability,
    ElliotCupModal
  }
})
export default class Home extends Vue {
  balanceDetails: Reactive<BalancesResponse> = null;
  images: Reactive<IBalancesImages> = null;

  isLoading = false;
  hasError = false;
  openRegisterPanel = false;

  @CustomerModule.Getter('isRegulatoryStatusBlocked')
  readonly isOverdue!: boolean;

  @CustomerModule.Getter('getCustomerId')
  readonly customerApiId!: string;

  @CustomerModule.Getter('getAccountStatus')
  readonly accountStatusResponse!: AccountStates;

  @ProvideReactive()
  get shouldTriggerHomeAnimations() {
    return !UIModule.getIsOpenWelcomeCurtain;
  }

  get showInvestmentsWidgets() {
    return this.accountStatusResponse?.clientEnabled ?? false;
  }

  created() {
    this.load();
  }

  mounted() {
    trackEvents.trackHomePageView();
    clearNextRoutes();
  }

  reloadBalanceSummary() {
    this.load();
    trackEvents.trackReloadWorthErrorClick();
  }

  toogleOpenRegisterPanel() {
    this.openRegisterPanel = !this.openRegisterPanel;
  }

  async load() {
    this.isLoading = true;
    this.hasError = false;

    try {
      await this.loadHeroBalance();
    } catch {
      this.hasError = true;
      trackEvents.trackWorthErrorLoad();
    } finally {
      this.isLoading = false;
    }
  }

  private async loadHeroBalance() {
    this.balanceDetails = await getBalances();
  }
}
